<script setup lang="ts">
import { Frequency, OrganizationUserRole } from "docuchatcommontypes";

defineProps<{
  title?: string;
  message?: string;
}>();

const open = defineModel<boolean>({ default: false });

const { user, organization, isPlanExpired } = storeToRefs(useUserStore());
const { canUseTeamFeatures } = useLimits();

const shownOnBrowser = useLocalStorage("organization-message", false);
const shownOnSession = useSessionStorage("organization-message", false);
const showFrequency = computed(() => organization.value?.showDisclaimerFrequency ?? Frequency.Never);

const show = computed(() => {
  if (!canUseTeamFeatures.value || user.value?.organizationUserRole === OrganizationUserRole.SuperAdmin)
    return false;

  // This means the subscribe modal is open, so we don't want to show the welcome message
  if (isPlanExpired.value || !user.value?.isPlanChosen)
    return false;

  if (showFrequency.value === Frequency.EveryTime)
    return !shownOnSession.value;
  else if (showFrequency.value === Frequency.Once)
    return !shownOnBrowser.value;
  else
    return false;
});

watch(show, () => {
  if (show.value)
    open.value = true;
}, { immediate: true });

function handleClick() {
  shownOnSession.value = true;
  shownOnBrowser.value = true;
  open.value = false;
  throwConfetti();
}
</script>

<template>
  <UDashboardModal
    v-model="open"
    title="Welcome to Docuchat!"
    description="Your admin has a message for you. Please read it carefully before you get started."
    :ui="{
      width: 'sm:max-w-3xl',
    }"
    :close-button="null"
    :prevent-close="true"
  >
    <template #icon>
      <Logo
        class="h-8"
        icon-only
      />
    </template>

    <DcMarkdown :md="`# ${title ?? organization?.welcomeMessageTitle}\n\n${message ?? organization?.welcomeMessageContent}`" />

    <template #footer>
      <UButton
        size="lg"
        block
        @click.prevent="handleClick"
      >
        Understood. Let's get started!
      </UButton>
    </template>
  </UDashboardModal>
</template>
